import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MembershipEdit' })((theme) => ({
  root: {},
  button: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  cancelButton: {
    padding: '16px 25px',
  },
  destinationValue: theme.typography.body1Bold,
  contentCard: {
    padding: 16,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
  },
  radioLabel: {
    '& .MuiFormControlLabel-root': { alignItems: 'center', padding: '7px 9px 7px 15px' },
  },
  imageTextStack: { placeItems: 'center' },
  image: { width: 72, height: 48 },
  currentCard: theme.typography.body1Bold,
}));
