import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Stack } from '@mui/material';

import { DateConstants, formatDate } from '@ecp/utils/date';
import { Form, useField, useForm } from '@ecp/utils/form';

import type { RadioGroupOption } from '@ecp/components';
import {
  Alert,
  Button,
  HelpBox,
  NumberFormat,
  PhoneLink,
  RadioGroupWithOptions,
} from '@ecp/components';
import {
  LoadingButton,
  LoadingOverlay,
  ModalError,
} from '@ecp/features/servicing/shared/components';
import { type UseModalPathReturn as EditMembershipEditProps } from '@ecp/features/servicing/shared/routing';
import {
  getPersonByRefId,
  usePolicy,
  useUpdateCostcoMembership,
  useUser,
} from '@ecp/features/servicing/shared/state';
import type { CostcoMembershipTier } from '@ecp/features/servicing/shared/types';
import { getBrandData } from '@ecp/features/servicing/shared/util';
import {
  GraphicMembershipSmBusinessMemberImageUrl,
  GraphicMembershipSmExecutiveMemberImageUrl,
  GraphicMembershipSmGoldStarMemberImageUrl,
  GraphicUIAgentAvatarImageUrl,
} from '@ecp/themes/partners/costco';

import { membershipUpdateSchema } from './MembershipEdit.schema';
import { useStyles } from './MembershipEdit.styles';

export const MembershipEdit: React.FC<EditMembershipEditProps> = (props) => {
  const { goForward, policyNumber, reset } = props;

  if (!policyNumber) {
    throw new Error('Cannot call membership api without policyNumber');
  }

  const costcoMembershipRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    costcoMembershipRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { classes } = useStyles();
  const { isLoading: isPolicyLoading, policyData } = usePolicy(policyNumber);
  const { user, isLoading: isUserLoading } = useUser();
  const { updateCostcoMembership } = useUpdateCostcoMembership();

  const getRadioOption = useMemo(
    () =>
      (costcoMembershipTier: CostcoMembershipTier): RadioGroupOption => {
        const src =
          costcoMembershipTier === 'EXECUTIVE'
            ? GraphicMembershipSmExecutiveMemberImageUrl
            : costcoMembershipTier === 'GOLD STAR'
            ? GraphicMembershipSmGoldStarMemberImageUrl
            : GraphicMembershipSmBusinessMemberImageUrl;
        const displayText =
          costcoMembershipTier === 'EXECUTIVE'
            ? 'Executive'
            : costcoMembershipTier === 'GOLD STAR'
            ? 'Gold Star'
            : 'Business member';

        return {
          label: '',
          value: costcoMembershipTier,
          description: (
            <Stack spacing={1.5} direction='row' className={classes.imageTextStack}>
              <img src={src} alt={costcoMembershipTier} className={classes.image} />
              <span>{displayText}</span>
            </Stack>
          ),
        };
      },
    [classes.image, classes.imageTextStack],
  );

  const membershipOptions: RadioGroupOption[] = useMemo(
    () => [
      getRadioOption('EXECUTIVE'),
      getRadioOption('GOLD STAR'),
      getRadioOption('BUSINESS_MEMBERSHIP'),
    ],
    [getRadioOption],
  );

  const formContext = useForm({
    validations: membershipUpdateSchema,
  });
  const { handleSubmit, setError } = formContext;
  const membershipNumberField = useField({
    name: 'membershipNumber',
    formContext,
  });
  const costcoMembershipTierField = useField({
    name: 'costcoMembershipTier',
    formContext,
  });

  const [submitError, setSubmitError] = useState<string | undefined>();
  const customerServicePhone = getBrandData(policyData).mainCustServPhoneNum;
  const primaryNamedInsured = getPersonByRefId(policyData, policyData?.policy.primaryInsuredRefId);

  const onSubmit = useCallback(() => {
    if (!user) throw new Error('User is undefined');
    if (!primaryNamedInsured) throw new Error('Primary insured is undefined');
    handleSubmit(async (data) => {
      setSubmitError(undefined);
      const todaysDateAndTime = formatDate(new Date().toString(), DateConstants.ANSWERS_FORMAT);
      const { membershipNumber, costcoMembershipTier } = data;
      const { success, error } = await updateCostcoMembership({
        membershipNumber,
        membershipLevel: costcoMembershipTier,
        namedInsured: {
          firstName: primaryNamedInsured.firstName,
          lastName: primaryNamedInsured.lastName,
        },
        policyNumber,
        policySourceSystemName: policyData?.policy?.sourceSystemName,
        audit: {
          acknowledgement: 'Costco membership updated',
          requestedDate: todaysDateAndTime,
          requestedBy: user.email,
        },
        changeEffectiveDate: todaysDateAndTime,
      });

      if (success) {
        goForward();
      } else if (error) {
        setSubmitError(error);
        if (error === 'Invalid membership number')
          setError('membershipNumber', { message: 'Invalid Costco membership number' });
      }
    })();
  }, [
    goForward,
    handleSubmit,
    policyNumber,
    updateCostcoMembership,
    policyData?.policy?.sourceSystemName,
    user,
    primaryNamedInsured,
    setError,
  ]);

  if (submitError && submitError !== 'Invalid membership number')
    return (
      <ModalError
        errorTitle='edit your Costco membership number.'
        errorDescription='processing your request'
      />
    );

  return isPolicyLoading || isUserLoading ? (
    <LoadingOverlay />
  ) : (
    <Stack spacing={3} classes={classes.root}>
      <h1 id='edit-membership-edit-title'>Update Costco membership number</h1>

      <Form onSubmit={onSubmit} formProviderProps={formContext}>
        <Stack spacing={3}>
          <Stack spacing={2} className={classes.contentCard}>
            <Stack spacing={4}>
              <div>
                <RadioGroupWithOptions
                  {...costcoMembershipTierField}
                  label='What is your Costco membership level?'
                  variant='radioCard'
                  options={membershipOptions}
                  className={classes.radioLabel}
                />
              </div>
              <Stack spacing={3}>
                <NumberFormat
                  {...membershipNumberField}
                  label='New Costco membership number'
                  formatType='costcoMembershipNumber'
                  // type 'tel' to open numeric keypad on mobile device
                  type='tel'
                />
              </Stack>
              {submitError === 'Invalid membership number' && (
                <Alert type='error'>
                  <Stack spacing={2}>
                    <p>The number entered is not a valid Costco membership number.</p>
                    <p>
                      Check that the number you entered is accurate. If you’re still having issues
                      or you are not an active Costco member, please call{' '}
                      <PhoneLink withUnderlinedLinkStyle number={customerServicePhone} /> for help.
                    </p>
                  </Stack>
                </Alert>
              )}
              <HelpBox
                image={GraphicUIAgentAvatarImageUrl}
                data-testid='helpBoxIcon'
                content={
                  <p>
                    This updates the membership info on your policy. To update your Costco account,
                    please visit{' '}
                    <a href='https://costco.com' target='_new'>
                      Costco.com
                    </a>
                    .
                  </p>
                }
              />
            </Stack>
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }}>
            <LoadingButton
              trackingLabel='update_membership'
              trackingName='update_membership_button'
              variant='primary'
              type='submit'
              className={classes.button}
            >
              Update
            </LoadingButton>
            <Button variant='iconTextMedium' onClick={reset} className={classes.cancelButton}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </Stack>
  );
};
