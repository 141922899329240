import { apiRoot, buildRequestHeaders } from '../servicingApiConfig';
import type { ServicingResponse } from '../servicingRequest';
import { servicingRequest } from '../servicingRequest';
import type { UpdateCostcoMembershipRequest } from './types';

export const updateCostcoMembership = async (
  updateCostcoMembershipRequest: UpdateCostcoMembershipRequest,
): Promise<ServicingResponse<boolean>> => {
  const { policyNumber, ...body } = updateCostcoMembershipRequest;

  const headers = await buildRequestHeaders({ withUserToken: true });

  return servicingRequest({
    baseUrl: `${apiRoot}/ent-customer-policy/v1/policies/${policyNumber}/costcoMembership`,
    options: {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(body),
    },
  });
};
