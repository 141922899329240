import { generateHelpPath, PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { NavLink } from '../NavLink';
import { useStyles } from './BrandDisclaimer.styles';

export const BrandDisclaimer: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.bannerWrapper}>
      <small className={classes.banner}>
        This site is operated by a third party, not by Costco. Use of this (and any destination
        sites you reach through this site) is subject to the site's{' '}
        <NavLink
          to={generateHelpPath(PAGE_PATH.DISCLOSURES_TERMSOFUSE, 'connect')}
          className={classes.link}
        >
          Terms of Use
        </NavLink>{' '}
        and{' '}
        <NavLink
          to={generateHelpPath(PAGE_PATH.DISCLOSURES_PRIVACYPOLICY, 'connect')}
          className={classes.link}
        >
          Privacy Policy
        </NavLink>
        , rather than those of Costco.
      </small>
    </div>
  );
};
