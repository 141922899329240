import { flagValues } from '@ecp/utils/flags';

import { FullScreenModal } from '@ecp/features/servicing/shared/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';

import { MembershipConfirmation } from '../MembershipConfirmation';
import { MembershipEdit } from '../MembershipEdit/MembershipEdit';

const MembershipModalContent: React.FC = () => {
  const modalPath = useModalPath();

  const title = 'Update Costco membership';
  let body;

  switch (modalPath.currentStep) {
    case MODAL_STEP.MODAL_EDIT:
      body = <MembershipEdit {...modalPath} />;
      break;
    case MODAL_STEP.MODAL_CONFIRMATION:
      body = <MembershipConfirmation {...modalPath} />;
      break;
    default:
      body = <p>Default</p>;
  }

  return (
    <FullScreenModal title={title} onCloseModal={modalPath.reset}>
      {body}
    </FullScreenModal>
  );
};

export const MembershipModal: React.FC = () => {
  const modalPath = useModalPath();

  if (
    flagValues.MEMBERSHIP &&
    modalPath.currentFlow === MODAL_FLOW.EDIT_MEMBERSHIP &&
    !!modalPath.policyNumber
  )
    return <MembershipModalContent />;
  else return null;
};
