import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'BrandDisclaimer' })((theme) => ({
  bannerWrapper: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    alignSelf: 'flex-start',
  },
  banner: {
    ...theme.typography.body1,
    padding: '15px 30px',
    display: 'block',
    textAlign: 'center',
  },
  link: {
    color: theme.palette.secondary.contrastText,
    ':hover': { color: theme.palette.secondary.contrastText },
  },
}));
