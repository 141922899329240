import { useCallback } from 'react';

import { Stack } from '@mui/material';

import { Button } from '@ecp/components';
import { LoadingOverlay, OtherPoliciesEligible } from '@ecp/features/servicing/shared/components';
import type { OtherPoliciesEligibleProps } from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import { usePolicy, useUserPolicies } from '@ecp/features/servicing/shared/state';
import { IconMaterialDesignActionCheckCircle } from '@ecp/themes/base';

import { useStyles } from './MembershipConfirmation.styles';

export const MembershipConfirmation: React.FC<UseModalPathReturn> = (props) => {
  const { policyNumber, init } = props;
  const { isLoading: isPolicyLoading } = usePolicy(policyNumber);
  const { classes } = useStyles();
  const modalPath = useModalPath();
  const handleClose = useCallback(() => {
    modalPath.reset();
  }, [modalPath]);
  const { policies } = useUserPolicies({
    throwOnPolicyError: false,
  });

  const otherPolicies = policies
    .filter((policyData) => policyData?.policy.policyNumber !== policyNumber)
    .filter((policyData) => !policyData.isInactive)
    .filter((policyData) => policyData.policy.isCostcoMember);
  const handleUpdateMembershipClick = useCallback<
    NonNullable<OtherPoliciesEligibleProps['onClick']>
  >(
    (policyNumber) => init(policyNumber, MODAL_FLOW.EDIT_MEMBERSHIP, MODAL_STEP.MODAL_EDIT),
    [init],
  );

  return isPolicyLoading ? (
    <LoadingOverlay />
  ) : (
    <Stack spacing={4}>
      <Stack spacing={3}>
        <div className={classes.successIconWrapper}>
          <IconMaterialDesignActionCheckCircle className={classes.successIcon} />
        </div>
        <h2>Your request was successfully submitted!</h2>
        <Stack spacing={1.5}>
          <p>
            <span>Note: Your changes will not immediately appear online.</span>
          </p>
        </Stack>
      </Stack>
      <OtherPoliciesEligible
        otherPolicies={otherPolicies}
        title='Update your Costco membership for your other policies'
        buttonText='Update'
        onClick={handleUpdateMembershipClick}
      />
      <Button className={classes.closeButton} variant='primary' onClick={handleClose}>
        CLOSE
      </Button>
    </Stack>
  );
};
