import { useMutation } from '@tanstack/react-query';

import type { ResponseObject, ServicingRequestError } from '../servicingRequest';
import { updateCostcoMembership } from './api';
import type {
  UpdateCostcoMembershipErrorResponse,
  UpdateCostcoMembershipRequest,
  UpdateCostcoMembershipResponse,
} from './types';

export const useUpdateCostcoMembership = (): UpdateCostcoMembershipResponse<
  UpdateCostcoMembershipRequest,
  ResponseObject<boolean, UpdateCostcoMembershipErrorResponse>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: UpdateCostcoMembershipRequest) => {
      let updateCostcoMembershipError: UpdateCostcoMembershipErrorResponse | undefined = undefined;
      let success: boolean | undefined;

      await updateCostcoMembership(request)
        .then((res) => {
          success = res.payload;
        })
        .catch((error: ServicingRequestError) => {
          const errorCode = error.errorStack?.status?.messages?.[0].code;
          if (errorCode === 403017) {
            updateCostcoMembershipError = 'Invalid membership number';
          } else {
            updateCostcoMembershipError = 'Unknown error';
          }
        });

      const response: ResponseObject<boolean, UpdateCostcoMembershipErrorResponse> = {
        success,
        error: updateCostcoMembershipError,
      };

      return response;
    },
  });

  return {
    updateCostcoMembership: mutateAsync,
    isLoading: false,
    isError: isError,
    isSubmitting,
  };
};
