// These asset exports are specific to the costco partner
// The costco theme extends the connect (op-co) theme styles
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { theme as baseTheme } from '../../connect/src/theme';

const theme = { ...baseTheme, name: 'costco' };

export { theme };
export * from './assets';
// TODO Find a way to use '@ecp/themes/base'
/* eslint-disable @typescript-eslint/no-restricted-imports */
export { default as LogoOpcoImageUrl } from '../../../base/src/assets/generated/logos/logoConnect.svg';
export { default as LogoPrimaryImageUrl } from '../../../base/src/assets/generated/logos/logoCostco.svg';
export { default as LogoPrimaryAbbrvImageUrl } from '../../../base/src/assets/generated/logos/logoCostcoAbbrv.svg';
// These connect (op-co) exports override and extend exports from the base
// Re-exports below are overridden by the above
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
export * from '../../connect/src';
