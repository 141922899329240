import { Link } from '@ecp/components';

import { useStyles } from './ThirdPartyPrivacyNoticeLink.styles';

export const ThirdPartyPrivacyNoticeLink: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Link
      href='https://www.connectbyamfam.com/documents/ca_costco_privacy_notice.pdf'
      target='_blank'
      className={classes.link}
    >
      Third Party (Costco) Privacy Notice
    </Link>
  );
};
