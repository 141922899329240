import { useCallback } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { maskPaymentAccountNumber } from '@ecp/utils/common';

import { Button } from '@ecp/components';
import { MODAL_FLOW, MODAL_STEP, useModalPath } from '@ecp/features/servicing/shared/routing';
import { usePolicy } from '@ecp/features/servicing/shared/state';
import { IconUIEdit } from '@ecp/themes/base';

import { useStyles } from '../PolicyDetails.styles';

interface Props {
  policyNumber: string;
}
export const Membership: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { policyNumber } = props;
  const { policyData, isLoading } = usePolicy(policyNumber);
  const modalPath = useModalPath();

  const costcoMembershipNumber = maskPaymentAccountNumber(
    policyData?.policy.costcoMembershipNumber ?? '',
  );
  const heading = <p className={classes.subHeader}>Costco membership number</p>;

  const handleMembershipLinkClick = useCallback(() => {
    modalPath.init(policyNumber, MODAL_FLOW.EDIT_MEMBERSHIP, MODAL_STEP.MODAL_EDIT);
  }, [modalPath, policyNumber]);

  if (isLoading)
    return (
      <>
        {heading}
        <CircularProgress aria-label='Loading...' />
      </>
    );

  return policyData?.policy.isCostcoMember ? (
    <Stack spacing={0.5}>
      <Stack className={classes.titleBody} spacing={1} direction='row'>
        {heading}
        <Button
          className={classes.link}
          variant='iconText'
          onClick={handleMembershipLinkClick}
          icon={<IconUIEdit />}
          trackingLabel='edit_costco_membership_number'
          trackingName='edit_costco_membership_number_link'
          objectType='link'
        >
          Edit
        </Button>
      </Stack>
      <p>{costcoMembershipNumber}</p>
    </Stack>
  ) : null;
};
