import * as yup from 'yup';

import type { YupSchemaMap } from '@ecp/utils/form';

import type { CostcoMembershipTier } from '@ecp/features/servicing/shared/types';

export interface MembershipUpdateFormInputs {
  membershipNumber: string;
  costcoMembershipTier: CostcoMembershipTier;
}

export const membershipUpdateSchema: YupSchemaMap<MembershipUpdateFormInputs> = {
  membershipNumber: yup
    .string()
    .required('Membership number required')
    .length(12, 'Invalid membership number')
    .matches(/[0-9]{11}$/, 'Invalid membership number'),
  costcoMembershipTier: yup.string().required('Costco membership type required'),
};
