import type { GeneratedBrandData } from '../brand.types';

export const brand: GeneratedBrandData = {
  auto: {
    partnerCompanyName: 'CONNECT, powered by American Family Insurance',
    mainOperationHours: [
      'Monday - Friday: 7 a.m. - 10 p.m. CT',
      'Saturday: 8:30 a.m. - 7 p.m. CT',
      'Sunday: CLOSED',
    ],
    mainCustServPhoneNum: '1-888-404-5365',
    mainBrandNameShort: 'CONNECT, powered by American Family Insurance',
    brandingType: 'Private Label',
    salesOperationHours: [
      'Monday - Friday: 7 a.m. - 10 p.m. CT',
      'Saturday: 8:30 a.m. - 7 p.m. CT',
      'Sunday: CLOSED',
    ],
    salesPhoneNum: '1-888-404-5365',
    salesChatHours: ['Monday - Friday: 7 a.m. - 6 p.m. CT', 'Weekends: CLOSED'],
    serviceChatHours: ['Monday - Friday: 7 a.m. - 6 p.m. CT', 'Weekends: CLOSED'],
    claimPhoneNum: '1-888-404-5365',
    glassClaimPhoneNum: '1-888-404-5365',
    homeLockoutPhoneNum: '1-866-641-5443',
    inboundServiceEmail: 'PolicyDocs@connectbyamfam.com',
    roadsideAssistancePhoneNum: '1-888-742-4572',
    serviceAddress: {
      addressLine1: '3500 Packerland Drive',
      addressLine2: '',
      city: 'De Pere',
      state: 'WI',
      zip: '54115',
      type: 'General Service Mailing Address',
      zipPlus4: '9070',
    },
    paymentsAddress: {
      addressLine1: 'CONNECT, powered by American Family Insurance',
      addressLine2: 'PO Box 19036',
      attention: 'Attn: Accounting',
      city: 'Green Bay',
      state: 'WI',
      zip: '54307',
      type: 'Payments Mailing Address',
      zipPlus4: '9036',
    },
    uwCompany: 'CONNECT',
    uwCompanyCopyright: 'American Family Connect Property and Casualty Insurance Company, Inc.',
  },
  home: {
    partnerCompanyName: 'CONNECT, powered by American Family Insurance',
    mainOperationHours: [
      'Monday - Friday: 7 a.m. - 10 p.m. CT',
      'Saturday: 8:30 a.m. - 7 p.m. CT',
      'Sunday: CLOSED',
    ],
    mainCustServPhoneNum: '1-888-404-5365',
    mainBrandNameShort: 'CONNECT, powered by American Family Insurance',
    brandingType: 'Private Label',
    salesOperationHours: [
      'Monday - Friday: 7 a.m. - 10 p.m. CT',
      'Saturday: 8:30 a.m. - 7 p.m. CT',
      'Sunday: CLOSED',
    ],
    salesPhoneNum: '1-888-404-5365',
    salesChatHours: ['Monday - Friday: 7 a.m. - 6 p.m. CT', 'Weekends: CLOSED'],
    serviceChatHours: ['Monday - Friday: 7 a.m. - 6 p.m. CT', 'Weekends: CLOSED'],
    claimPhoneNum: '1-888-404-5365',
    glassClaimPhoneNum: '1-888-404-5365',
    homeLockoutPhoneNum: '1-866-641-5443',
    inboundServiceEmail: 'PolicyDocs@connectbyamfam.com',
    roadsideAssistancePhoneNum: '1-888-742-4572',
    serviceAddress: {
      addressLine1: '3500 Packerland Drive',
      addressLine2: '',
      city: 'De Pere',
      state: 'WI',
      zip: '54115',
      type: 'General Service Mailing Address',
      zipPlus4: '9070',
    },
    paymentsAddress: {
      addressLine1: 'CONNECT, powered by American Family Insurance',
      addressLine2: 'PO Box 5300',
      city: 'Binghamton',
      state: 'NY',
      zip: '13902',
      type: 'Payments Mailing Address',
      zipPlus4: '9953',
    },
    uwCompany: 'Homesite',
    uwCompanyCopyright: 'Homesite Group Incorporated',
  },
};
